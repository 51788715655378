// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-dashboard-js": () => import("/opt/build/repo/client/src/pages/dashboard.js" /* webpackChunkName: "component---src-pages-dashboard-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/client/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-login-js": () => import("/opt/build/repo/client/src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-messages-js": () => import("/opt/build/repo/client/src/pages/messages.js" /* webpackChunkName: "component---src-pages-messages-js" */)
}

